import { ComponentInternalInstance, VNode, watch } from 'vue';
import { useRoute } from 'vue-router';
export interface IBread {
  path?: string;
  meta: {
    title: string;
  };
}

let ins: ComponentInternalInstance | null = null;

function useBread() {
  const route = useRoute();
  function init(i: ComponentInternalInstance | null) {
    ins = i;
  }

  function setBread(val?: IBread[]) {
    ins && ins.exposed!.setBread(val);
  }

  // 自定义左侧
  const setLeftCom = (v?: VNode) => {
    ins && ins.exposed!.setLeftCom(v);
  };

  // 每次路由变化，会先调用一次默认面包屑
  // 而后如果有自定义，则会被覆盖
  watch(
    () => route.path,
    () => {
      setBread();
      setLeftCom();
    }
  );

  return { init, setBread, setLeftCom };
}

export default useBread;
