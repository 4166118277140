import request from '@/utils/request';
import { Base64 } from 'js-base64';
enum apiUrl {
  businessList = '/enterprise/industry',
  businessAccountList = '/enterprise/members/all/search',
  createBusiness = '/enterprise',
  createUser = '/user/add',
  userAccountList = '/user/search',
  enterpriseAuth = '/enterprise',
  userAuth = '/user/verify',
  smsCode = '/user/send/smsCode',
  smsCodeLogin = '/v1/authentication/mobile',
  accountLogin = '/v1/authentication/form',
  addEquity = '/operation/quota-packages',
  addTtsAudioEquity = '/operation/tts-quota-packages',
  addTtsVideoEquity = '/operation/video-package',
  ttsAudioContract = '/operation/tts-quota-packages/contract-no',
  ttsVideoContract = '/operation/video-package/contract-no',
  ttsAudioStopPage = '/operation/tts-quota-packages/stop',
  ttsVideoStopPage = '/operation/video-package/stop',
  exportAudioRecord = '/operation/tts-cost',
  exportVideoRecord = '/operation/video-detail',
  tokenCheck = '/v1/authentication/refresh',
  stopPage = '/operation/quota-packages/stop',
  obtain = '/user/info',
  verificationToken = '/user/info/verify-smscode',
  brandList = '/operation/trademark',
  customerInfo = '/operation/quota-packages/customer-info',
}
function base64(): string {
  const Username = 'B-PWsbJZd8g';
  const Password = 'MVAqrJOGnhcsf_ch';
  const base = Base64.encode(`${Username}:${Password}`);
  return base;
}
export interface IAccountList {
  pageNum: number;
  pageSize: number;
  total?: number;
  phone: string; // 手机号
  actorName: string; // 数智人名称
  enterpriseName?: string; // 企业名称
  customerName?: string; // 客户名称
  userName?: string; // 姓名
  trademarkId?: number | string; // 品牌
  account?: string;
}

export interface IAccountCreate {
  name?: string;
  phone: string;
  nickName?: string;
}
export interface equityList {
  phone: string;
  name: string;
  userId: number;
  enterpriseName: string;
  enterpriseId: number;
  enterpriseMemberNum: string;
  videoNum: string;
  singleTime: string;
  totalTime: string;
  capacity: string;
  previewNum: string;
  humanFlag: string;
  resolutionRatio: string;
  downloadFlag: string;
  watermarkFlag: string;
  templateFlag: string;
  num: number;
  unit: string;
  file: any; //合同文件
  startTime: string;
}

export class UserApi {
  static verificationToken(data: any) {
    return request.post({ url: apiUrl.verificationToken, data });
  }
  static obtainInformation(data: any) {
    return request.get({ url: apiUrl.obtain, params: data });
  }
  static businessList() {
    return request.get({ url: apiUrl.businessList });
  }
  static businessAccountList(data: IAccountList) {
    delete data.total;
    return request.get({
      url: apiUrl.businessAccountList,
      params: data,
    });
  }
  static userAccountList(data: IAccountList) {
    delete data.total;
    return request.get({
      url: apiUrl.userAccountList,
      params: data,
    });
  }
  static getBrandList() {
    return request.get({
      url: apiUrl.brandList,
    });
  }
  static createBusiness(data: IAccountCreate) {
    return request.post({ url: apiUrl.createBusiness, data });
  }
  static createUser(data: IAccountCreate) {
    return request.post({ url: apiUrl.createUser, data });
  }
  static tokenCheck(data: any) {
    const headers: any = {};
    headers.Authorization = `Basic ${data.access_token}`;
    const list = new FormData();
    list.append('refresh_token', data.refresh_token as any);
    list.append('grant_type', 'refresh_token' as any);
    list.append('target', 2 as any);
    return request.post({
      url: apiUrl.tokenCheck,
      data: list,
      headers,
    });
  }
  static getEnterpriseAuth(id: number) {
    return request.get({ url: `${apiUrl.enterpriseAuth}/${id}` });
  }
  static putEnterpriseAuth(id: number, data: any, type?: any) {
    const headers: any = {};
    if (type == 2) {
      headers.platform = 2;
    }

    return request.put({
      url: `${apiUrl.enterpriseAuth}/${id}`,
      data,
      headers,
    });
  }
  static getUserAuth(id: number) {
    return request.get({ url: `${apiUrl.userAuth}/${id}` });
  }
  static putUserAuth(data: any, type?: any) {
    const headers: any = {};
    if (type == 2) {
      headers.platform = 2;
    }
    return request.put({
      url: `${apiUrl.userAuth}`,
      data,
      headers,
    });
  }
  static getSmsCode(data: FormData) {
    const headers: any = {};
    headers.Authorization = null;
    return request.post({ url: apiUrl.smsCode, data });
  }
  static smsCodeLogin(data: FormData) {
    const base = base64();
    const headers: any = {};
    headers.Authorization = `Basic ${base}`;
    return request.post({ url: apiUrl.smsCodeLogin, data, headers });
  }
  static accountLogin(data: FormData) {
    const base = base64();
    const headers: any = {};
    headers.Authorization = `Basic ${base}`;
    return request.post({ url: apiUrl.accountLogin, data, headers });
  }
  static addEquity(data: equityList) {
    return request.post({ url: apiUrl.addEquity, data });
  }

  static addTtsAudioEquity(data: equityList) {
    return request.post({ url: apiUrl.addTtsAudioEquity, data });
  }
  static addTtsVideoEquity(data: equityList) {
    return request.post({ url: apiUrl.addTtsVideoEquity, data });
  }

  static equityConsumption(data: equityList) {
    return request.get({ url: apiUrl.addEquity, params: data });
  }
  static equityTtsAduioConsumption(data: equityList) {
    return request.get({ url: apiUrl.addTtsAudioEquity, params: data });
  }
  static equityTtsVideoConsumption(data: equityList) {
    return request.get({ url: apiUrl.addTtsVideoEquity, params: data });
  }
  static stopPage(data: equityList) {
    return request.post({ url: apiUrl.stopPage, data });
  }
  static ttsAudioStopPage(data: { id: number }) {
    return request.post({ url: `${apiUrl.ttsAudioStopPage}?id=${data.id}` });
  }
  static ttsVideoStopPage(data: { id: number }) {
    return request.post({ url: apiUrl.ttsVideoStopPage, data });
  }
  static editContract(data: { quotaPackagesId: number; contractNo: string }) {
    return request.put({ url: apiUrl.addEquity, data });
  }
  static editAudioContract(data: { quotaPackagesId: number; contractNo: string }) {
    return request.put({ url: apiUrl.ttsAudioContract, data });
  }
  static editVideoContract(data: { id: number; contractNo: string }) {
    return request.put({ url: apiUrl.ttsVideoContract, data });
  }
  static exportAudioRecord(data: { id: number }) {
    return request.get({
      url: apiUrl.exportAudioRecord,
      params: data,
    });
  }
  static exportVideoRecord(data: { id: number }) {
    return request.get({ url: apiUrl.exportVideoRecord, params: data });
  }
  static getCustomerInfo(data: {
    userId?: number;
    enterpriseId?: number;
    enterpriseMemberId?: number;
  }) {
    return request.get({ url: apiUrl.customerInfo, params: data });
  }
}
