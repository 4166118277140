import type { Router } from 'vue-router';
import Storage from '@/utils/storage';
export function createPermissionGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const token = Storage.getItem('Bearer');
    if (to.name === 'login') {
      Storage.clear();
      next();
    } else {
      if (token) {
        next();
      } else {
        Storage.clear();
        next({ path: '/user/login' }); // 否则全部重定向到登录页
      }
    }
  });
}
